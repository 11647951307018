@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@font-face {
  font-family: "Brandon Grotesque";
  src: url("../../../fonts/Brandon_reg.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

ul {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
}

ul,
ol,
div {
  margin: 0;
  padding: 0;
}

html {
  font-family: "Noto Sans JP", sans-serif;
  color: #041524;
  font-size: 10px;
  -webkit-font-feature-settings: "palt";
          font-feature-settings: "palt";
  scroll-behavior: smooth;
}
@media screen and (max-width: 1200px) {
  html {
    min-width: 1200px;
  }
}
@media screen and (max-width: 767px) {
  html {
    min-width: 100%;
  }
}

.button__start-now {
  background-color: #baff42;
  border: 10px solid #f1f6f6;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 2em;
  color: #041524;
  width: 450px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .button__start-now {
    height: 110px;
  }
}
@media screen and (max-width: 767px) {
  .button__start-now {
    font-size: 15px;
    width: 100%;
    height: 80px;
    border: 5px solid #f1f6f6;
  }
}
.button__start-now--margin {
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .button__start-now--margin {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.button__start-now img {
  width: 26px;
  height: 26px;
  margin-left: 20px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.button__start-now:hover {
  letter-spacing: 0.2em;
}
.button__start-now:hover img {
  margin-left: 30px;
}
.button__inquiry {
  background-color: #01b5b5;
  border: 10px solid #f1f6f6;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: #fff;
  width: 450px;
  height: 120px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .button__inquiry {
    height: 110px;
  }
}
@media screen and (max-width: 767px) {
  .button__inquiry {
    font-size: 15px;
    width: 100%;
    height: 80px;
    border: 5px solid #f1f6f6;
  }
}
.button__inquiry--gray {
  border: 10px solid #F1F6F6;
  z-index: 1;
}
.button__inquiry img {
  width: 26px;
  height: 26px;
  margin-left: 20px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.button__inquiry:hover {
  letter-spacing: 0.2em;
}
.button__inquiry:hover img {
  margin-left: 30px;
}
.button__primary {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #01b5b5;
  margin-top: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .button__primary {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 40px;
    font-size: 13px;
  }
}
.button__primary.no-margin {
  margin: 0;
}
@media screen and (max-width: 767px) {
  .button__primary.end {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    margin-top: 50px;
  }
}
.button__primary:hover {
  letter-spacing: 0.3em;
}
.button__primary:hover > .button__primary-arrow {
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-left: 15px;
}
.button__primary-arrow {
  background: #fff;
  padding: 20px;
  border-radius: 50%;
  margin-left: 10px;
  -webkit-box-shadow: 0 10px 30px 0px rgba(48, 76, 81, 0.3);
          box-shadow: 0 10px 30px 0px rgba(48, 76, 81, 0.3);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .button__primary-arrow {
    margin-left: 20px;
    width: 60px;
    height: 60px;
  }
}
.button__primary-arrow::after {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .button__primary-arrow::after {
    width: 10px;
    height: 10px;
  }
}

.title {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.6666666667em;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .title {
    font-size: 23px;
  }
}
.title__wrap--center {
  text-align: center;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .subtitle {
    font-size: 10px;
  }
}

.title__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .title__container {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .title__container {
    max-width: 100%;
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .title__container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}

.t-mv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media screen and (max-width: 767px) {
  .t-mv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.t-mv__text {
  color: #fff;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-mv__text {
    padding-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__text {
    top: 30%;
  }
}
.t-mv__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 767px) {
  .t-mv__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.t-mv__flex h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__flex h1 {
    font-size: 38px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__flex h1 {
    font-size: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__flex h1 {
    font-size: 20px;
  }
}
.t-mv__flex span {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 1.3333333333em;
  margin: 0 20px;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__flex span {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__flex span {
    font-size: 20px;
    margin: 25px 0;
  }
}
.t-mv__text-image {
  display: none;
}
@media screen and (max-width: 767px) {
  .t-mv__text-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: -10%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.t-mv__logo {
  margin-top: 99px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__logo {
    width: 200px;
    height: auto;
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__logo {
    width: 180px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__logo {
    width: 150px;
    margin-top: 30px;
  }
}
.t-mv__logo img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-mv__logo-text {
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__logo-text {
    width: 150px;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__logo-text {
    width: 120px;
    margin-top: 0;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__logo-text {
    width: 100px;
  }
}
.t-mv__logo-text img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-mv__image {
  position: relative;
  width: 100%;
}
.t-mv__image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.t-mv__image::before {
  content: "";
  background-image: url("./src/img-new/top/mv-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.t-mv__image.active::before {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(4, 21, 29, 0.6)), to(rgba(4, 21, 29, 0.6)));
  background: linear-gradient(0deg, rgba(4, 21, 29, 0.6) 0%, rgba(4, 21, 29, 0.6) 100%);
}
.t-mv__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-mv__button {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__button {
    width: 100%;
    padding: 0 6%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 40px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.t-faq {
  width: 100%;
  height: 400px;
  background: url("./src/img-new/faq/faq-bg.jpg") no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 150px;
}
@media screen and (max-width: 767px) {
  .t-faq {
    background: url("./src/img-new/faq/faq-bg-sp.jpg") no-repeat;
    height: 250px;
    padding: 0 4%;
    margin-top: 80px;
  }
}
.t-faq__content {
  max-width: 800px;
  width: 100%;
}

.t-column {
  padding: 150px 0;
}
@media screen and (max-width: 767px) {
  .t-column {
    padding: 75px 0;
  }
}
.t-column__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.t-column__list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .t-column__list {
    padding-left: 4%;
    margin-top: 50px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-column__list {
    padding-left: 4%;
  }
}
.t-column__item {
  width: 360px;
  height: 500px;
  margin-right: 30px;
  -webkit-transition: 0.3 ease-in-out;
  transition: 0.3 ease-in-out;
}
@media screen and (max-width: 767px) {
  .t-column__item {
    width: 250px;
    height: auto;
    margin-right: 40px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-column__item {
    width: 342px;
  }
}
.t-column__item:hover {
  opacity: 0.7;
}
.t-column__date {
  font-size: 16px;
  font-weight: 500;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .t-column__date {
    font-size: 13px;
  }
}
.t-column__image {
  width: 360px;
  height: 240px;
  border-radius: 5px;
  overflow: hidden;
  margin: 20px 0 40px;
}
@media screen and (max-width: 767px) {
  .t-column__image {
    width: 250px;
    height: 165px;
    margin: 10px 0 25px;
  }
}
.t-column__image > img {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-column__content {
  margin-bottom: 30px;
}
.t-column__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5555555556em;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .t-column__title {
    font-size: 14px;
  }
}
.t-column__text {
  font-size: 14px;
  line-height: 1.7142857143em;
}
@media screen and (max-width: 767px) {
  .t-column__text {
    font-size: 12px;
  }
}
.t-column__category {
  font-size: 12px;
  color: #9aaeae;
}
@media screen and (max-width: 767px) {
  .t-column__category {
    font-size: 10px;
  }
}

.t-news {
  padding-bottom: 150px;
}
@media screen and (max-width: 767px) {
  .t-news {
    padding-bottom: 75px;
  }
}
.t-news__list {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .t-news__list {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-news__list {
    max-width: 100%;
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .t-news__list {
    margin-top: 50px;
  }
}
.t-news__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #f1f6f6;
  padding: 30px 0;
  -webkit-transition: 0.3 ease-in-out;
  transition: 0.3 ease-in-out;
}
@media screen and (max-width: 767px) {
  .t-news__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.t-news__item:first-child {
  border-top: 1px solid #f1f6f6;
}
.t-news__item:hover {
  opacity: 0.7;
}
.t-news__wrap {
  width: 285px;
  margin-right: 115px;
}
@media screen and (max-width: 767px) {
  .t-news__wrap {
    width: 100%;
    margin: 0 0 20px;
  }
}
.t-news__date, .t-news__category, .t-news__title {
  font-size: 16px;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .t-news__date, .t-news__category, .t-news__title {
    font-size: 13px;
  }
}
.t-news__date, .t-news__title {
  font-weight: 500;
}
.t-news__date {
  font-family: "Montserrat", sans-serif;
  color: #0d9e9e;
  margin-right: 100px;
}
@media screen and (max-width: 767px) {
  .t-news__date {
    margin-right: 25px;
  }
}
.t-news__category {
  color: #9aaeae;
}
@media screen and (max-width: 767px) {
  .t-news__category {
    font-size: 10px;
  }
}

.t-contact {
  width: 100%;
  background-color: #01b5b5;
  padding: 140px 0 130px;
}
@media screen and (max-width: 767px) {
  .t-contact {
    padding: 65px 0 60px;
  }
}
.t-contact__container {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .t-contact__container {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-contact__container {
    max-width: 100%;
    padding: 0 4%;
  }
}
.t-contact__title {
  font-size: 60px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  text-align: center;
  letter-spacing: 0.2em;
  margin-bottom: 80px;
}
@media screen and (max-width: 767px) {
  .t-contact__title {
    font-size: 30px;
    margin-bottom: 30px;
  }
}
.t-contact__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  position: relative;
}
@media screen and (max-width: 767px) {
  .t-contact__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.t-contact__button-wrap {
  position: absolute;
  top: 55px;
  left: 0;
}
@media screen and (max-width: 767px) {
  .t-contact__button-wrap {
    position: relative;
    top: auto;
    left: auto;
  }
}
.t-contact__button {
  width: 350px;
  height: 100px;
  background-color: #01b5b5;
  border: 10px solid #f1f6f6;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .t-contact__button {
    width: 100%;
    height: 75px;
    margin-top: 20px;
    border: 5px solid #f1f6f6;
  }
}
.t-contact__button:not(:last-child) {
  margin-bottom: 25px;
}
.t-contact__button:hover {
  background-color: #0d9e9e;
}
.t-contact__button--registration {
  height: 250px;
  background-color: #baff42;
  border-radius: 125px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding-top: 20px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .t-contact__button--registration {
    height: 100px;
    border-radius: 50px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-top: 0;
  }
}
.t-contact__button--registration > span {
  font-size: 16px;
  font-weight: 500;
  color: #0d9e9e !important;
}
@media screen and (max-width: 767px) {
  .t-contact__button--registration > span {
    font-size: 10px;
  }
}
.t-contact__button--registration:hover {
  background-color: #f4f4f4;
}
.t-contact__button > h4 {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1.2777777778em;
  text-align: center;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .t-contact__button > h4 {
    font-size: 23px;
    margin-bottom: 5px;
  }
}
.t-contact__button > h5 {
  font-size: 26px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .t-contact__button > h5 {
    font-size: 18px;
  }
}
.t-contact__button > span {
  font-size: 12px;
  color: #fff;
  letter-spacing: 0.2em;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .t-contact__button > span {
    font-size: 10px;
  }
}
.t-contact__button-deco {
  width: 18px;
  height: 18px;
  background-color: #0e9e9e;
  border-radius: 50%;
  margin-bottom: 30px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .t-contact__button-deco {
    width: 9px;
    height: 9px;
    margin-bottom: 10px;
  }
}
.t-contact__img {
  width: auto;
  height: auto;
}
@media screen and (max-width: 767px) {
  .t-contact__img {
    width: 100%;
  }
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  font-size: 14px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  /* ----- Hamburger Menu Toggle ----- */
}
@media screen and (max-width: 767px) {
  .header {
    font-size: 10px;
  }
}
.header.scrolled .header__navigation > a {
  color: #041524;
}
.header.scrolled .header__navigation > a:hover {
  color: #0d9e9e;
}
.header.scrolled .header__login > * {
  color: #01b5b5;
}
.header.scrolled .header__login > *:hover {
  color: #041524;
}
.header.scrolled .header__content-wrap {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 48, 76, 0.1);
          box-shadow: 0 0 10px 0 rgba(0, 48, 76, 0.1);
}
.header.scrolled .header__content {
  height: 100px;
}
@media screen and (max-width: 1200px) {
  .header.scrolled .header__content {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .header.scrolled .header__content {
    height: 50px;
  }
}
.header.scrolled .header__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
}
.header.scrolled .header__inquiry {
  background-color: #baff42;
  width: 200px;
  height: 100px;
  font-size: 16px;
  font-weight: 900;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 1200px) {
  .header.scrolled .header__inquiry {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header.scrolled .header__inquiry {
    display: none;
  }
}
.header.scrolled .header__inquiry:hover {
  background-color: #0d9e9e;
  color: #fff;
}
.header.scrolled .header__arrow {
  color: #041524;
}
.header.scrolled .header__submenu {
  background-color: #fff;
  -webkit-box-shadow: 0 5px 20px 0 rgba(48, 76, 81, 0.2);
          box-shadow: 0 5px 20px 0 rgba(48, 76, 81, 0.2);
  bottom: -20px;
}
.header.scrolled .header__submenu-links a {
  color: #041524;
}
.header.scrolled .header__submenu-links a:hover {
  color: #0d9e9e;
}
.header.scrolled .header__menu-btn-burger {
  background-color: #041524;
}
.header.scrolled .header__menu-btn-burger::after {
  content: "";
  background-color: #041524;
}
.header.scrolled .header__menu-btn-burger::before {
  content: "";
  background-color: #041524;
}
.header__content-wrap {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.header__content-wrap.active {
  background-color: #fff;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 48, 76, 0.1);
          box-shadow: 0 0 10px 0 rgba(0, 48, 76, 0.1);
}
.header__content-wrap.active .header__login a {
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .header__content-wrap.active .header__logo {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.header__content {
  width: 100%;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .header__content {
    height: 50px;
  }
}
@media screen and (max-width: 767px) {
  .header__content {
    padding: 0 10px 0 20px;
  }
}
.header__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 20px;
}
@media screen and (max-width: 767px) {
  .header__flex {
    padding-left: 10px;
  }
}
.header__flex:last-child {
  padding-left: 0;
}
.header__logo {
  display: none;
}
.header__logo-image {
  width: 180px;
  height: 50px;
}
@media screen and (max-width: 1200px) {
  .header__logo-image {
    width: 100px;
    height: 28px;
  }
}
@media screen and (max-width: 767px) {
  .header__logo-image {
    width: 100px;
    height: 28px;
  }
}
.header__logo-image img {
  width: 100%;
  height: auto;
}
.header__navigation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 900;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 1200px) {
  .header__navigation {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .header__navigation {
    display: none;
    padding: 60px 60px 0 60px;
  }
}
.header__navigation .has-submenu {
  position: relative;
}
.header__navigation .has-submenu > a.active {
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .header__navigation .has-submenu.active {
    color: #041524;
  }
}
.header__navigation > * {
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  cursor: pointer;
}
.header__navigation > *:not(:last-child) {
  margin-right: 30px;
}
@media screen and (max-width: 767px) {
  .header__navigation > *:not(:last-child) {
    margin-right: 0;
  }
}
.header__navigation > *:hover {
  color: #0d9e9e;
}
.header__navigation-sp {
  display: none;
  font-weight: 900;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 1200px) {
  .header__navigation-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 60px 60px 0 120px;
  }
}
@media screen and (max-width: 767px) {
  .header__navigation-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 60px 60px 0 60px;
  }
}
.header__submenu {
  width: 180px;
  height: 200px;
  background-color: #fff;
  border-radius: 10px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  position: absolute;
  bottom: -20px;
  left: -55px;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: opacity 0.3s ease, max-height 0.6s ease, background-color 0.3s ease;
  transition: opacity 0.3s ease, max-height 0.6s ease, background-color 0.3s ease;
}
.header__submenu.active {
  opacity: 1;
  max-height: 200px;
}
@media screen and (max-width: 767px) {
  .header__sublist {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.header__sublist.active .header__submenu-links-sp {
  opacity: 1;
  z-index: 1;
  max-height: 100%;
  visibility: visible;
}
.header__sublist.active .header__submenu-sp {
  color: #0d9e9e;
}
.header__sublist.active .header__submenu-sp::after {
  content: "";
  -webkit-transform: rotate(-138deg);
          transform: rotate(-138deg);
  margin-bottom: -10px;
}
@media screen and (max-width: 767px) {
  .header__submenu-sp {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
}
.header__submenu-sp a:hover {
  color: #0d9e9e;
}
.header__submenu-sp:after {
  content: "";
  display: inline-block;
  height: 8px;
  width: 8px;
  border-bottom: 3px solid;
  border-right: 3px solid;
  margin-left: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.header__submenu-content {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header__submenu-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.header__submenu-links a {
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1em;
  color: #041524;
  margin-bottom: 20px;
}
.header__submenu-links a:last-child {
  margin-bottom: 0;
}
.header__submenu-links a:hover {
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .header__submenu-links-sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    font-size: 14px;
    padding-left: 30px;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
    max-height: 0;
  }
}
.header__submenu-links-sp a {
  margin-top: 30px;
}
.header__submenu-links-sp a:hover {
  color: #0d9e9e;
}
.header__login {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 900;
  letter-spacing: 0.1em;
  margin-right: 20px;
}
@media screen and (max-width: 767px) {
  .header__login {
    margin-right: 30px;
  }
}
.header__login > * {
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.header__login > *::after {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  margin-left: 10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .header__login > *::after {
    height: 3px;
    width: 3px;
    border-bottom: 1.5px solid;
    border-right: 1.5px solid;
  }
}
.header__login > *:hover {
  color: #0d9e9e;
}
.header__login img {
  margin-left: 10px;
}
.header__arrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.header__arrow::after {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  margin-left: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.header__inquiry {
  display: none;
}
.header__menu-btn {
  display: none;
  position: relative;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
@media screen and (max-width: 1200px) {
  .header__menu-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }
}
@media screen and (max-width: 767px) {
  .header__menu-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
  }
}
.header__menu-btn-burger {
  width: 30px;
  height: 3px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.header__menu-btn-burger::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
.header__menu-btn-burger::before {
  content: "";
  position: absolute;
  width: 30px;
  height: 3px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}
.header__content-wrap-sp.active .header__container-sp {
  height: 100%;
  overflow-y: scroll;
  background: #fff;
  padding-bottom: 60px;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
.header__content-wrap-sp.active .header__navigation-sp {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 17px;
}
.header__content-wrap-sp.active .header__navigation-sp > * {
  color: #041524;
  margin-bottom: 50px;
}
.header__content-wrap-sp.active .header__navigation-sp a:hover {
  color: #0d9e9e;
}
.header__active-bg {
  -webkit-transform: translateX(150%);
          transform: translateX(150%);
}
.header__container-sp {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__container-sp {
    display: block;
    position: fixed;
    width: 100%;
    z-index: -1;
    top: 50px;
    right: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}
@media screen and (max-width: 767px) {
  .header__container-sp {
    display: block;
    position: fixed;
    width: 100%;
    z-index: -1;
    top: 50px;
    right: 0;
    -webkit-transform: translateX(150%);
            transform: translateX(150%);
  }
}
.header__button {
  display: none;
}
@media screen and (max-width: 1200px) {
  .header__button {
    width: 100%;
    padding: 0 6%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
@media screen and (max-width: 767px) {
  .header__button {
    width: 100%;
    padding: 0 6%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.header__button .button__start-now,
.header__button .button__inquiry {
  width: 100%;
}

/* ----- Hamburger Menu ----- */
.header__menu-btn.open .header__menu-btn-burger {
  -webkit-transform: translateX(-50px);
          transform: translateX(-50px);
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.header__menu-btn.open .header__menu-btn-burger::before {
  -webkit-transform: rotate(45deg) translate(35px, -35px);
          transform: rotate(45deg) translate(35px, -35px);
  background-color: #000;
}
.header__menu-btn.open .header__menu-btn-burger::after {
  -webkit-transform: rotate(-45deg) translate(35px, 35px);
          transform: rotate(-45deg) translate(35px, 35px);
  background-color: #000;
}

.footer {
  width: 100%;
  padding: 100px 0 40px 0;
  background-color: #f1f6f6;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 55px 0 30px;
  }
}
.footer a:hover {
  opacity: 0.7;
}
.footer__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 6%;
  }
}
.footer__link-list {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .footer__link-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
  }
}
.footer__link-col {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}
@media screen and (max-width: 767px) {
  .footer__link-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.footer__link-set a {
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 2.8333333333em;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .footer__link-set a {
    border-top: 1px solid #f5f6f0;
    padding: 5px 0;
    font-size: 12px;
  }
}
@media screen and (max-width: 767px) {
  .footer__link-set a:last-child {
    border-bottom: 1px solid #f5f6f0;
  }
}
.footer__link-set a::before {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  margin-right: 10px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.footer__link-set.margin-right {
  margin-right: 100px;
}
@media screen and (max-width: 767px) {
  .footer__link-set.margin-right {
    margin-right: 0;
  }
}
.footer__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .footer__details {
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.footer__details a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-left: 100px;
  width: 180px;
  height: auto;
}
@media screen and (max-width: 767px) {
  .footer__details a {
    margin-left: 0;
  }
}
.footer__logo {
  width: 100%;
}
.footer__copyright {
  font-size: 12px;
  font-family: "Brandon Grotesque", sans-serif;
  color: #01b5b5;
  letter-spacing: 0.1em;
  margin-top: 85px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .footer__copyright {
    font-size: 10px;
    margin-top: 30px;
  }
}

.t-mv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
@media screen and (max-width: 767px) {
  .t-mv {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.t-mv__text {
  color: #fff;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-mv__text {
    padding-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__text {
    top: 30%;
  }
}
.t-mv__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 767px) {
  .t-mv__flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
.t-mv__flex h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 40px;
  font-weight: 900;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__flex h1 {
    font-size: 38px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__flex h1 {
    font-size: 25px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__flex h1 {
    font-size: 20px;
  }
}
.t-mv__flex span {
  font-size: 30px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 1.3333333333em;
  margin: 0 20px;
  text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__flex span {
    font-size: 28px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__flex span {
    font-size: 20px;
    margin: 25px 0;
  }
}
.t-mv__text-image {
  display: none;
}
@media screen and (max-width: 767px) {
  .t-mv__text-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: -10%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
}
.t-mv__logo {
  margin-top: 99px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__logo {
    width: 200px;
    height: auto;
    margin-top: 50px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__logo {
    width: 180px;
    margin-top: 70px;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__logo {
    width: 150px;
    margin-top: 30px;
  }
}
.t-mv__logo img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-mv__logo-text {
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-mv__logo-text {
    width: 150px;
    height: auto;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__logo-text {
    width: 120px;
    margin-top: 0;
  }
}
@media screen and (max-width: 320px) {
  .t-mv__logo-text {
    width: 100px;
  }
}
.t-mv__logo-text img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-mv__image {
  position: relative;
  width: 100%;
}
.t-mv__image img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.t-mv__image::before {
  content: "";
  background-image: url("./src/img-new/top/mv-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.t-mv__image.active::before {
  background: -webkit-gradient(linear, left bottom, left top, from(rgba(4, 21, 29, 0.6)), to(rgba(4, 21, 29, 0.6)));
  background: linear-gradient(0deg, rgba(4, 21, 29, 0.6) 0%, rgba(4, 21, 29, 0.6) 100%);
}
.t-mv__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 80px;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-mv__button {
    margin-top: 40px;
  }
}
@media screen and (max-width: 767px) {
  .t-mv__button {
    width: 100%;
    padding: 0 6%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 40px;
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

.t-about {
  padding: 120px 0;
}
@media screen and (max-width: 767px) {
  .t-about {
    padding: 0;
  }
}
.t-about__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .t-about__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 6%;
  }
}
.t-about__info {
  width: 50%;
  margin-left: 10%;
  padding-top: 90px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-about__info {
    padding-top: 60px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-about__info {
    margin-left: 4%;
  }
}
@media screen and (max-width: 767px) {
  .t-about__info {
    width: 100%;
    margin-left: 0;
    padding-top: 0;
  }
}
.t-about__text {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 2.2222222222em;
  margin-top: 60px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-about__text {
    font-size: 25px;
  }
}
@media screen and (max-width: 767px) {
  .t-about__text {
    font-size: 14px;
    line-height: 2.4285714286em;
    margin-top: 40px;
  }
}
.t-about__image {
  width: 50%;
}
@media screen and (max-width: 767px) {
  .t-about__image {
    width: 100%;
    margin-top: 50px;
  }
}
.t-about__image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (max-width: 767px) {
  .t-procedure {
    padding-top: 50px;
  }
}
.t-procedure__content {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .t-procedure__content {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-procedure__content {
    max-width: 100%;
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .t-procedure__content {
    padding: 0;
  }
}
.t-procedure__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t-procedure__header {
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2em;
  text-align: center;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .t-procedure__header {
    font-size: 17px;
    letter-spacing: 0.3em;
    line-height: 1.8823529412em;
    margin-top: 40px;
  }
}
.t-procedure__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  margin-top: 55px;
}
@media screen and (max-width: 767px) {
  .t-procedure__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.t-procedure__flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t-procedure__item {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2em;
  width: 455px;
  height: 105px;
  padding: 0 60px;
  border-radius: 52.5px;
  background-color: #f4f4f4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 767px) {
  .t-procedure__item {
    font-size: 13px;
    width: 225px;
    padding: 0 19px;
    border-radius: 30px;
  }
}
.t-procedure__item .green {
  color: #01b5b5;
}
.t-procedure__item.right {
  position: relative;
  right: -15%;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .t-procedure__item.right {
    right: 10%;
    margin-bottom: 10px;
  }
}
.t-procedure__item.left {
  position: relative;
  left: 15%;
  margin-top: 20px;
}
@media screen and (max-width: 767px) {
  .t-procedure__item.left {
    left: 10%;
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .t-procedure__item.border-right {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media screen and (max-width: 767px) {
  .t-procedure__item.border-left {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.t-procedure__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .t-procedure__image {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .t-procedure__block {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 767px) {
  .t-procedure__item-image {
    width: 100%;
    height: auto;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.t-procedure__item-image:first-child {
  padding-top: 50px;
}
@media screen and (max-width: 767px) {
  .t-procedure__item-image img {
    width: 100%;
    height: 150px;
    -o-object-fit: contain;
       object-fit: contain;
  }
}

.t-point {
  position: relative;
}
.t-point__container {
  background-color: #23caca;
  padding: 80px 20px 0 20px;
  height: 975px;
  position: relative;
  margin-top: 120px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-point__container {
    height: 875px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-point__container {
    height: 775px;
    padding: 80px 0 0;
  }
}
@media screen and (max-width: 767px) {
  .t-point__container {
    height: 100%;
    padding: 50px 6%;
  }
}
.t-point__container::before {
  content: "";
  background-image: url("./src/img-new/point/point-arrow.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  top: -25px;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .t-point__container::before {
    background-size: 20% auto;
    top: -20px;
  }
}
.t-point::after {
  content: "";
  background-image: url("./src/img-new/point/point-arrow.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  top: -80px;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .t-point::after {
    background-size: 20% auto;
    top: -60px;
  }
}
.t-point__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t-point .title,
.t-point .subtitle {
  color: #fff;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-point__content {
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .t-point__content {
    position: relative;
  }
}
.t-point__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .t-point__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.t-point__item {
  width: 32.5%;
  height: 676px;
  background-color: #fff;
  border-radius: 11px;
  padding: 100px 95px;
  letter-spacing: 0.2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0 0 40px 0 rgba(48, 76, 81, 0.4);
          box-shadow: 0 0 40px 0 rgba(48, 76, 81, 0.4);
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .t-point__item {
    height: 600px;
    padding: 40px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-point__item {
    height: 500px;
  }
}
@media screen and (max-width: 767px) {
  .t-point__item {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    padding: 30px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
@media screen and (max-width: 320px) {
  .t-point__item {
    padding: 25px;
  }
}
.t-point__text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #01b5b5;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .t-point__text {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
.t-point__header {
  font-size: 26px;
  font-weight: 500;
  line-height: 1.7692307692em;
  margin-bottom: 30px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .t-point__header {
    margin-bottom: 10px;
    font-size: 17px;
  }
}
@media screen and (max-width: 320px) {
  .t-point__header {
    font-size: 14px;
  }
}
.t-point__description {
  font-size: 14px;
  line-height: 1.7142857143em;
  text-align: justify;
}
@media screen and (max-width: 767px) {
  .t-point__description {
    font-size: 12px;
  }
}
.t-point__image {
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .t-point__image {
    margin-top: 30px;
  }
}
.t-point__image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.t-point__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: -4%;
}
@media screen and (max-width: 767px) {
  .t-point__button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    position: absolute;
    padding: 0 6%;
    bottom: 0;
    margin-top: 0;
    -webkit-transform: translateY(130px);
            transform: translateY(130px);
  }
}

.t-flow {
  padding-top: 80px;
}
@media screen and (max-width: 767px) {
  .t-flow {
    padding-top: 180px;
    margin-bottom: 50px;
  }
}
.t-flow__content {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .t-flow__content {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .t-flow__content {
    max-width: 100%;
    padding: 0 4%;
  }
}
.t-flow__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t-flow__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 80px;
}
@media screen and (max-width: 767px) {
  .t-flow__list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 20px;
  }
}
.t-flow__item {
  width: 20%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
@media screen and (max-width: 767px) {
  .t-flow__item {
    width: 100%;
    margin-top: 20px;
  }
}
.t-flow__item::after {
  content: "";
  position: absolute;
  right: -40px;
  height: 25px;
  width: 25px;
  border-bottom: 2px solid #9aaeae;
  border-right: 2px solid #9aaeae;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .t-flow__item::after {
    height: 15px;
    width: 15px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: 0 auto;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}
.t-flow__item:last-of-type::after {
  content: "";
  display: none;
}
.t-flow__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (max-width: 767px) {
  .t-flow__info {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.t-flow__steps {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (max-width: 767px) {
  .t-flow__steps {
    margin-right: 20px;
  }
}
.t-flow__step {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .t-flow__step {
    font-size: 10px;
  }
}
.t-flow__num {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .t-flow__num {
    font-size: 23px;
  }
}
.t-flow__image {
  width: 79px;
  height: auto;
  margin: 20px 0;
}
@media screen and (max-width: 767px) {
  .t-flow__image {
    width: 50px;
    margin-right: 20px;
  }
}
.t-flow__title {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2em;
  margin-bottom: 20px;
}
@media screen and (max-width: 767px) {
  .t-flow__title {
    font-size: 14px;
    margin-bottom: 0;
  }
}
.t-flow__description {
  font-size: 14px;
  letter-spacing: 0.2em;
  line-height: 1.7142857143em;
  color: #9aaeae;
}
@media screen and (max-width: 767px) {
  .t-flow__description {
    font-size: 12px;
    margin-bottom: 40px;
  }
}
.t-flow__button-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 100px;
}
@media screen and (max-width: 767px) {
  .t-flow__button-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 50px;
  }
}
.t-flow__button {
  width: 32.5%;
  height: 120px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 30px 0 rgba(48, 76, 81, 0.2);
          box-shadow: 0 10px 30px 0 rgba(48, 76, 81, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 40px 0 60px;
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .t-flow__button {
    width: 100%;
    height: 100px;
    margin-bottom: 10px;
    padding: 0 40px;
  }
}
.t-flow__button::after {
  content: "";
  display: inline-block;
  height: 5px;
  width: 5px;
  border-bottom: 2px solid;
  border-right: 2px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
@media screen and (max-width: 767px) {
  .t-flow__button::after {
    height: 7px;
    width: 7px;
    border-bottom: 2px solid #01b5b5;
    border-right: 2px solid #01b5b5;
  }
}
.t-flow__button:hover {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 2px solid #0d9e9e;
}
.t-flow__button-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.t-flow__button-text {
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .t-flow__button-text {
    font-size: 10px;
  }
}
.t-flow__button-title {
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .t-flow__button-title {
    font-size: 18px;
  }
}
.t-flow__button-title.margin {
  margin-top: 20px;
}

.lp {
  position: relative;
}
.lp__banner {
  background-image: url("./src/img-new/lp/banner-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 963px;
  z-index: 9;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__banner {
    height: 863px;
  }
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__banner {
    height: 763px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__banner {
    height: 730px;
  }
}
@media screen and (max-width: 767px) {
  .lp__banner {
    background-image: url("./src/img-new/lp/banner-background-sp.png");
    height: 100%;
    padding-bottom: 43.5px;
  }
}
.lp__container {
  padding: 0 110px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__container {
    padding: 0 55px;
  }
}
@media screen and (max-width: 767px) {
  .lp__container {
    padding: 0 2%;
  }
}
.lp__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  padding-top: 80px;
}
@media screen and (max-width: 767px) {
  .lp__header {
    padding-top: 10px;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.lp__logo {
  width: 287px;
  height: 80px;
}
@media screen and (max-width: 767px) {
  .lp__logo {
    width: 130px;
    height: 36px;
  }
}
.lp__button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.lp__contact {
  width: 272px;
  height: 72px;
  border: 2px solid #0d9e9e;
  border-radius: 50px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .lp__contact {
    width: 47px;
    height: 47px;
    border-radius: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.lp__contact-image {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
@media screen and (max-width: 767px) {
  .lp__contact-image {
    width: 25px;
    height: 25px;
    margin-right: 0;
  }
}
.lp__contact-text {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: #0d9e9e;
  letter-spacing: 0.1em;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
@media screen and (max-width: 767px) {
  .lp__contact-text {
    font-size: 6px;
  }
}
.lp__inquiry {
  width: 272px;
  height: 72px;
  border: 5px solid #fff;
  border-radius: 50px;
  background-color: #0d9e9e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 767px) {
  .lp__inquiry {
    width: 47px;
    height: 47px;
    border: 2px solid #fff;
    border-radius: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.lp__inquiry-image {
  width: 26px;
  height: 26px;
  margin-left: 20px;
}
@media screen and (max-width: 767px) {
  .lp__inquiry-image {
    width: 25px;
    height: 25px;
    margin-left: 0;
  }
}
.lp__inquiry-text {
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  letter-spacing: 0.08em;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .lp__inquiry-text {
    font-size: 6px;
  }
}
.lp__inquiry-text:hover {
  letter-spacing: 0.2em;
}
.lp__image {
  float: right;
  width: 52.74%;
}
.lp__image img {
  width: 100%;
}
.lp__content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .lp__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 0 4%;
    margin-top: 64px;
  }
}
.lp__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 50%;
}
@media screen and (max-width: 767px) {
  .lp__info {
    width: 100%;
  }
}
.lp__banner-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 2.0833333333em;
  letter-spacing: 0.14em;
  color: #fff;
  text-shadow: 0 2px 2px rgba(4, 21, 36, 0.4);
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__banner-text {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .lp__banner-text {
    font-size: 15px;
  }
}
.lp__banner-title {
  font-size: 40px;
  font-weight: 500;
  line-height: 1.75em;
  letter-spacing: 0.18em;
  color: #fff;
  text-shadow: 0 2px 2px rgba(4, 21, 36, 0.4);
  margin-top: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__banner-title {
    font-size: 32px;
  }
}
@media screen and (max-width: 767px) {
  .lp__banner-title {
    font-size: 25px;
  }
}
.lp__button-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 50px;
}
@media screen and (max-width: 767px) {
  .lp__button-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 28px;
  }
}
.lp__button-item {
  width: 230px;
  height: 110px;
  background-color: #f9be00;
  border-radius: 50vw;
  -webkit-box-shadow: 0 5px 2px 0 rgba(4, 21, 36, 0.5);
          box-shadow: 0 5px 2px 0 rgba(4, 21, 36, 0.5);
  padding: 15px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin-right: 15px;
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__button-item {
    height: 95px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__button-item {
    height: 90px;
  }
}
@media screen and (max-width: 767px) {
  .lp__button-item {
    width: 315px;
    height: 70px;
    margin-bottom: 10px;
    margin-right: 0;
    padding: 0;
    -webkit-box-shadow: 0 2px 2px 0 rgba(4, 21, 36, 0.5);
            box-shadow: 0 2px 2px 0 rgba(4, 21, 36, 0.5);
  }
}
.lp__button-item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 767px) {
  .lp__button-item:last-child {
    margin-bottom: 0;
  }
}
.lp__button-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.75em;
  letter-spacing: 0.04em;
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__button-title {
    font-size: 16px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__button-title {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .lp__button-title {
    font-size: 14px;
  }
}
.lp__button-text {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 1.7142857143em;
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__button-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__button-text {
    font-size: 10px;
  }
}
@media screen and (max-width: 767px) {
  .lp__button-text {
    font-size: 10px;
  }
}
.lp__banner-image {
  float: right;
  width: 46%;
  padding-top: 130px;
}
@media screen and (max-width: 767px) {
  .lp__banner-image {
    width: 100%;
    padding-top: 50px;
  }
}
.lp__banner-image img {
  width: 100%;
}
.lp__sections {
  margin-top: -4.5%;
}
@media screen and (max-width: 767px) {
  .lp__sections {
    margin-top: 0;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__sections {
    margin-top: -5%;
  }
}
.lp__problem {
  background-image: url("./src/img-new/lp/problem-background.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  padding: 130px 0 180px;
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__problem {
    padding: 100px 55px 80px 55px;
  }
}
@media screen and (max-width: 767px) {
  .lp__problem {
    background-image: url("./src/img-new/lp/problem-background-sp.png");
    padding: 45px 0 65px;
  }
}
.lp__problem-content {
  position: relative;
}
.lp__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.lp__problem-container {
  max-width: 1500px;
  margin: 0 auto;
  padding-top: 40px;
}
@media screen and (max-width: 767px) {
  .lp__problem-container {
    max-width: 100%;
    margin-bottom: 50px;
  }
}
.lp__problem-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media screen and (max-width: 767px) {
  .lp__problem-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.lp__problem-list--one {
  padding: 0px 20%;
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__problem-list--one {
    padding: 0px 12%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__problem-list--one {
    padding: 0px 13%;
  }
}
@media screen and (max-width: 767px) {
  .lp__problem-list--one {
    padding: 0;
  }
}
.lp__problem-list--two {
  padding: 0 5%;
}
@media screen and (max-width: 767px) {
  .lp__problem-list--two {
    padding: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.lp__problem-item {
  width: 430px;
  height: 110px;
  background-color: #f1f6f6;
  border-radius: 50vw;
  -webkit-box-shadow: 0 5px 5px 0 rgba(16, 20, 24, 0.4);
          box-shadow: 0 5px 5px 0 rgba(16, 20, 24, 0.4);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 50px;
}
@media screen and (max-width: 767px) {
  .lp__problem-item {
    -webkit-box-shadow: none;
            box-shadow: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 25px;
    border-top-right-radius: 25px;
    width: 225px;
    height: 80px;
    padding: 0 26px;
  }
}
@media screen and (max-width: 320px) {
  .lp__problem-item {
    width: 210px;
  }
}
.lp__problem-item p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (max-width: 767px) {
  .lp__problem-item p {
    font-size: 13px;
    letter-spacing: 0.2em;
  }
}
.lp__problem-item .green {
  color: #0d9e9e;
}
.lp__problem-item .black {
  color: #041524;
}
@media screen and (max-width: 767px) {
  .lp__problem-item.right {
    margin: 0 auto 10px 0;
  }
}
@media screen and (max-width: 767px) {
  .lp__problem-item.left {
    margin: 0 0 10px auto;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
  }
}
@media screen and (max-width: 767px) {
  .lp__problem-item.center-right {
    margin: 0 auto 10px 20px;
    border-radius: 25px;
  }
}
@media screen and (max-width: 767px) {
  .lp__problem-item.center-left {
    margin: 0 20px 10px auto;
    border-radius: 25px;
  }
}
.lp__problem-image {
  width: 27.5%;
  margin: 0 auto;
  position: absolute;
  top: 18%;
  left: 0;
  right: 0;
}
@media screen and (max-width: 767px) {
  .lp__problem-image {
    width: 27.5%;
    margin: 0 40px 0 auto;
    position: absolute;
    top: 42%;
    z-index: -1;
  }
}
@media screen and (max-width: 320px) {
  .lp__problem-image {
    margin: 0 0 0 auto;
    top: 45%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__problem-image {
    width: 35.5%;
  }
}
.lp__problem-image img {
  width: 100%;
}
.lp__point {
  position: relative;
}
.lp__point-container {
  background-color: #23caca;
  padding: 80px 20px 0 20px;
  height: 1010px;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__point-container {
    height: 950px;
  }
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__point-container {
    height: 915px;
  }
}
@media screen and (max-width: 767px) {
  .lp__point-container {
    height: 100%;
    padding: 50px 6% 80px 6%;
  }
}
.lp__point-container::before {
  content: "";
  background-image: url("./src/img-new/point/point-arrow.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  top: -25px;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .lp__point-container::before {
    background-size: 15% auto;
    top: -20px;
  }
}
@media screen and (max-width: 320px) {
  .lp__point-container::before {
    top: -15px;
  }
}
.lp__point-container::after {
  content: "";
  background-image: url("./src/img-new/point/point-arrow.png");
  background-size: auto;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  top: -80px;
  right: 0;
  bottom: 0;
  left: 0;
}
@media screen and (max-width: 767px) {
  .lp__point-container::after {
    background-size: 15% auto;
    top: -50px;
  }
}
@media screen and (max-width: 320px) {
  .lp__point-container::after {
    top: -40px;
  }
}
.lp__point-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.lp__point-title .title,
.lp__point-title .subtitle {
  color: #fff;
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__point-content {
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .lp__point-content {
    position: relative;
  }
}
.lp__point-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 40px;
}
@media screen and (max-width: 767px) {
  .lp__point-list {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.lp__point-item {
  width: 32.5%;
  height: 700px;
  background-color: #fff;
  border-radius: 11px;
  padding: 60px 70px;
  letter-spacing: 0.2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-shadow: 0 0 40px 0 rgba(48, 76, 81, 0.4);
          box-shadow: 0 0 40px 0 rgba(48, 76, 81, 0.4);
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__point-item {
    height: 650px;
    padding: 50px;
  }
}
@media screen and (min-width: 980px) and (max-width: 1366px) {
  .lp__point-item {
    height: 600px;
    padding: 40px;
  }
}
@media screen and (max-width: 767px) {
  .lp__point-item {
    height: auto;
    width: 100%;
    margin-bottom: 10px;
    padding: 30px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.lp__point-item-content {
  height: 100%;
}
.lp__point-text {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #01b5b5;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .lp__point-text {
    font-size: 10px;
    margin-bottom: 10px;
  }
}
.lp__point-header {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7692307692em;
  text-align: center;
  letter-spacing: 0.1em;
  height: 50px;
}
@media screen and (max-width: 767px) {
  .lp__point-header {
    margin-bottom: 10px;
    font-size: 17px;
    letter-spacing: 0.2em;
    height: auto;
  }
}
@media screen and (max-width: 320px) {
  .lp__point-header {
    font-size: 16px;
    height: auto;
  }
}
.lp__point-description {
  font-size: 14px;
  line-height: 1.7142857143em;
  letter-spacing: 0.04em;
  text-align: justify;
  margin-top: 30px;
}
@media screen and (max-width: 767px) {
  .lp__point-description {
    font-size: 12px;
    letter-spacing: 0.1em;
  }
}
.lp__point-image {
  width: 100%;
}
@media screen and (max-width: 767px) {
  .lp__point-image {
    margin-top: 22.5px;
  }
}
.lp__point-image img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  vertical-align: middle;
}
.lp__point-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  left: 50%;
  bottom: -6%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
@media screen and (max-width: 767px) {
  .lp__point-button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 0 6%;
    bottom: -2%;
    margin-top: 0;
  }
}
.lp__service {
  width: 100%;
  background: url("./src/img-new/service/bg-service.jpg") center no-repeat;
  background-size: cover;
  padding: 155px 0 100px;
}
@media screen and (max-width: 767px) {
  .lp__service {
    background: url("./src/img-new/service/bg-service-sp.png") center no-repeat;
    background-size: cover;
    padding: 85px 30px 37.5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__service {
    padding: 100px 0;
  }
}
.lp__service-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 70px;
  padding-right: 95px;
}
@media screen and (max-width: 767px) {
  .lp__service-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-right: 0;
    margin-top: 42.5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__service-row {
    padding-right: 20px;
  }
}
.lp__service-image {
  width: 1030px;
  height: 680px;
  margin-right: 50px;
}
@media screen and (max-width: 767px) {
  .lp__service-image {
    width: 100%;
    height: auto;
    margin: 0 0 22.5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__service-image {
    width: 50%;
    height: auto;
    margin-right: 40px;
  }
}
.lp__service-text {
  font-size: 18px;
  line-height: 2.6em;
}
@media screen and (max-width: 767px) {
  .lp__service-text {
    font-size: 12px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__service-text {
    font-size: 16px;
  }
}
.lp__faq {
  padding: 50px 0 80px;
}
@media screen and (max-width: 767px) {
  .lp__faq {
    padding: 27.5px 0 45px;
  }
}
.lp__faq-list {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
@media screen and (max-width: 767px) {
  .lp__faq-list {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .lp__faq-list {
    max-width: 100%;
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .lp__faq-list {
    padding: 0 30px;
  }
}
.lp__faq-item {
  width: 100%;
  padding: 40px 50px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 10px rgba(4, 21, 36, 0.4);
          box-shadow: 0 0 10px rgba(4, 21, 36, 0.4);
  cursor: pointer;
}
@media screen and (max-width: 767px) {
  .lp__faq-item {
    padding: 15px 12.5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px rgba(4, 21, 36, 0.4);
            box-shadow: 0 0 5px rgba(4, 21, 36, 0.4);
  }
}
.lp__faq-item:not(:last-child) {
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .lp__faq-item:not(:last-child) {
    margin-bottom: 8.5px;
  }
}
.lp__faq-question {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 767px) {
  .lp__faq-question {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.lp__faq-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
@media screen and (max-width: 767px) {
  .lp__faq-wrap {
    width: 93%;
  }
}
.lp__faq-wrap::before {
  content: "Q.";
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 500;
  color: #0d9e9e;
  margin-right: 12px;
}
@media screen and (max-width: 767px) {
  .lp__faq-wrap::before {
    font-size: 17px;
    margin-right: 10px;
  }
}
.lp__faq-text {
  font-size: 23px;
  font-weight: 500;
  letter-spacing: 0.18em;
}
@media screen and (max-width: 767px) {
  .lp__faq-text {
    font-size: 14px;
  }
}
.lp__faq-text--sm {
  font-size: 15px;
  font-weight: 400;
  line-height: 2.4em;
}
@media screen and (max-width: 767px) {
  .lp__faq-text--sm {
    font-size: 12px;
  }
}
.lp__faq-answer {
  margin-top: 25px;
  display: none;
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out;
}
@media screen and (max-width: 767px) {
  .lp__faq-answer {
    margin-top: 15px;
  }
}
.lp__faq-answer.active {
  display: block;
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out;
}
.lp__faq-icon {
  width: 26px;
  height: 26px;
  margin-left: 30px;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  .lp__faq-icon {
    width: 12.5px;
    height: 12.5px;
    margin-top: 3px;
  }
}
.lp__faq-icon.close {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.lp__faq-icon-line {
  width: 4px;
  height: 100%;
  background-color: #041524;
  position: absolute;
}
@media screen and (max-width: 767px) {
  .lp__faq-icon-line {
    width: 1.5px;
    height: 12.5px;
  }
}
.lp__faq-icon-line:first-child {
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.lp__faq-icon-line:last-child {
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
}
.lp__contact-wrap {
  width: 100%;
  height: 500px;
  background: url("./src/img-new/contact/bg-contact.jpg") center no-repeat;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 767px) {
  .lp__contact-wrap {
    height: auto;
    background: url("./src/img-new/contact/bg-contact-sp.jpg") center no-repeat;
    background-size: cover;
    padding: 100px 30px 47.5px;
  }
}
.lp__contact-content {
  width: 980px;
  height: 260px;
  background-color: #fff;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
}
@media screen and (max-width: 767px) {
  .lp__contact-content {
    width: 100%;
    height: 140px;
  }
}
.lp__contact-label {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.04em;
  text-align: center;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .lp__contact-label {
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 9px;
    z-index: 1;
  }
}
.lp__contact-vector {
  position: absolute;
  right: 27px;
  bottom: -7px;
}
@media screen and (max-width: 767px) {
  .lp__contact-vector {
    width: 135px;
    height: 130px;
    right: -15px;
    bottom: 70px;
  }
}
.lp__footer {
  width: 100%;
  height: 240px;
  background-color: #F1F6F6;
  padding: 65px 100px 38px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .lp__footer {
    height: auto;
    padding: 50px 30px 27.5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 1600px) {
  .lp__footer {
    padding: 65px 4% 38px;
  }
}
.lp__footer-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 15px;
}
@media screen and (max-width: 767px) {
  .lp__footer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 37.5px;
  }
}
.lp__footer-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media screen and (max-width: 767px) {
  .lp__footer-logo {
    margin-bottom: 35px;
  }
}
.lp__footer-logo-img {
  width: 270px;
  height: 75px;
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .lp__footer-logo-img {
    width: 135px;
    height: 37.5px;
    margin-bottom: 5px;
  }
}
.lp__footer-logo-text {
  font-size: 18px;
  color: #0D9E9E;
  text-align: left;
}
@media screen and (max-width: 767px) {
  .lp__footer-logo-text {
    font-size: 14px;
  }
}
.lp__footer-button {
  width: 400px;
  height: auto;
  border-radius: 55px;
  border: 2px solid #0D9E9E;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 17px 10px;
}
@media screen and (max-width: 767px) {
  .lp__footer-button {
    width: 100%;
    height: 83px;
  }
}
.lp__footer-img {
  width: 31px;
  height: 30px;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .lp__footer-img {
    width: 24px;
    height: 23.5px;
  }
}
.lp__footer-wrap > h4 {
  font-size: 30px;
  color: #0D9E9E;
  line-height: 35px;
}
@media screen and (max-width: 767px) {
  .lp__footer-wrap > h4 {
    font-size: 20px;
  }
}
.lp__footer-wrap > span {
  font-size: 18px;
  color: #0D9E9E;
}
@media screen and (max-width: 767px) {
  .lp__footer-wrap > span {
    font-size: 12px;
  }
}
.lp__footer-copyright {
  font-family: "Brandon Grotesque", sans-serif;
  font-size: 12px;
  color: #0D9E9E;
  width: 100%;
}
@media screen and (max-width: 767px) {
  .lp__footer-copyright {
    font-size: 10px;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.lp-pagebanner {
  background-image: url("./src/img-new/lp/pagebanner-contact.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 540px;
}
@media screen and (max-width: 767px) {
  .lp-pagebanner {
    height: 200px;
    background-position: initial;
  }
}
.lp-pagebanner__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-top: 60px;
}
@media screen and (max-width: 767px) {
  .lp-pagebanner__text {
    padding-top: 20px;
  }
}
.lp-pagebanner__text .title,
.lp-pagebanner__text .subtitle {
  color: #fff;
}

.contact__container {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 80px 0 100px;
}
@media screen and (max-width: 767px) {
  .contact__container {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .contact__container {
    max-width: 100%;
    padding: 0 4%;
  }
}
.contact__progress {
  width: 100%;
  max-width: 800px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 0 auto 55px;
}
@media screen and (max-width: 767px) {
  .contact__progress {
    margin-bottom: 40px;
  }
}
.contact__progress-item {
  width: 180px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #0d9e9e;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
@media screen and (max-width: 767px) {
  .contact__progress-item {
    width: 30%;
    height: 40px;
  }
}
.contact__progress-item:not(:last-child)::before {
  content: "";
  width: 100px;
  height: 2px;
  background: url("./src/img-new/contact/progress-deco.png") center no-repeat;
  background-size: cover;
  position: absolute;
  top: 50%;
  right: -115px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
@media screen and (max-width: 767px) {
  .contact__progress-item:not(:last-child)::before {
    right: -25%;
    z-index: -1;
  }
}
.contact__progress-item.done {
  background-color: #84C9CC;
  border: 1px solid #84C9CC;
}
.contact__progress-item.done > .contact__progress-text {
  color: #fff;
}
.contact__progress-item.active {
  background-color: #0d9e9e;
}
.contact__progress-item.active > .contact__progress-text {
  color: #fff;
}
.contact__progress-text {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #0d9e9e;
}
@media screen and (max-width: 767px) {
  .contact__progress-text {
    font-size: 12px;
  }
}
@media screen and (max-width: 320px) {
  .contact__progress-text {
    font-size: 10px;
  }
}
.contact__progress-text > span.medium {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
}
@media screen and (max-width: 767px) {
  .contact__progress-text > span.medium {
    font-size: 15px;
    margin-right: 5px;
  }
}
.contact__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 2.4em;
  letter-spacing: 0.2em;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .contact__text {
    font-size: 14px;
  }
}

.form {
  margin-top: 75px;
}
@media screen and (max-width: 767px) {
  .form {
    margin-top: 50px;
  }
}
.form__list {
  position: relative;
}
.form__inquiry {
  display: block;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.form__inquiry.active {
  opacity: 1;
  visibility: visible;
  max-height: 150vh;
}
.form__member {
  width: 100%;
  opacity: 0;
  visibility: hidden;
  max-height: 0;
}
.form__member.active {
  opacity: 1;
  visibility: visible;
  max-height: 250vh;
}
.form__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 60px;
}
@media screen and (max-width: 767px) {
  .form__item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    margin-bottom: 40px;
  }
}
.form__item--col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  margin-bottom: 0 !important;
}
.form__label {
  width: 280px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media screen and (max-width: 767px) {
  .form__label {
    width: 100%;
    margin-bottom: 15px;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.form__label--checkbox {
  width: auto;
}
.form__label--checkbox > .form__label-req {
  margin-left: 10px;
}
.form__label-text {
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.04em;
}
@media screen and (max-width: 767px) {
  .form__label-text {
    font-size: 14px;
  }
}
.form__label-req {
  font-size: 13px;
  letter-spacing: 0.5em;
  line-height: 13px;
  color: #0d9e9e;
  border: 1px solid #0d9e9e;
  border-radius: 13px;
  padding: 6px 31px 5.5px;
}
@media screen and (max-width: 767px) {
  .form__label-req {
    margin-left: 10px;
    width: 60px;
    height: 20px;
    padding: 0;
    padding-left: 5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.form__input-dropdown {
  width: 100%;
  max-width: 816px;
  height: 50px;
  border: 1px solid #808080;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 0.2em;
}
.form__has-dropdown {
  width: 100%;
  max-width: 816px;
  opacity: 0;
  max-height: 100px;
}
.form__input-wrap {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  max-width: 816px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2em;
}
.form__input {
  position: relative;
  width: 100%;
  height: 50px;
  border: 1px solid #808080;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .form__input {
    width: 100%;
    height: 40px;
    padding: 2px 10px;
  }
}
.form__input--arrow {
  background: url("./src/img-new/icons/icon-dropdown.svg") no-repeat 98% center;
  background-size: 20px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form__input::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__input::-moz-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__input:-ms-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__input::-ms-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__input::placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__input.postal {
  width: 70%;
}
.form__autofill {
  width: 25%;
  height: 50px;
  background-color: #0d9e9e;
  border: none;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
@media screen and (max-width: 767px) {
  .form__autofill {
    height: 40px;
    font-size: 14px;
  }
}
.form__autofill:hover {
  background-color: #fff;
  border: 1px solid #0d9e9e;
  color: #0d9e9e;
}
.form__textarea {
  width: 100%;
  height: 150px;
  border: 1px solid #808080;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  resize: none;
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Noto Sans JP", sans-serif;
  letter-spacing: 0.2em;
}
@media screen and (max-width: 767px) {
  .form__textarea {
    width: 100%;
    height: 40px;
    padding: 2px 10px;
  }
}
.form__textarea::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__textarea::-moz-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__textarea:-ms-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__textarea::-ms-input-placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__textarea::placeholder {
  font-size: 16px;
  line-height: 16px;
  color: #B3B3B3;
}
.form__privacy {
  width: 100%;
  height: 200px;
  border: 1px solid #808080;
  overflow-y: auto;
  margin-top: 40px;
  padding: 23px;
}
@media screen and (max-width: 767px) {
  .form__privacy {
    margin-top: 0;
    padding: 15px;
  }
}
.form__privacy-text {
  font-size: 16px;
  letter-spacing: 0.2em;
  line-height: 2.4em;
}
@media screen and (max-width: 767px) {
  .form__privacy-text {
    font-size: 14px;
  }
}
.form__checkbox-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 20px;
}
.form__checkbox {
  position: absolute;
  opacity: 0;
}
.form__checkbox + label, .form__checkbox + .mwform-checkbox-field-text {
  position: relative;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.04em;
  white-space: nowrap;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
@media screen and (max-width: 767px) {
  .form__checkbox + label, .form__checkbox + .mwform-checkbox-field-text {
    font-size: 1.4rem;
  }
}
.form__checkbox + label::before, .form__checkbox + .mwform-checkbox-field-text::before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  border: 1px solid #081524;
  border-radius: 5px;
}
.form__checkbox:checked + label::after, .form__checkbox:checked + .mwform-checkbox-field-text::after {
  content: "";
  position: absolute;
  top: 8px;
  left: 4px;
  width: 14px;
  height: 11px;
  background: url("./src/img-new/icons/icon-checkmark.svg") no-repeat;
  background-size: cover;
}
@media screen and (max-width: 767px) {
  .form__checkbox:checked + label::after, .form__checkbox:checked + .mwform-checkbox-field-text::after {
    top: 5px;
  }
}
.form__checkbox:checked + label::before {
  background-color: #0d9e9e;
  border: 1px solid #0d9e9e;
}
.form__checkbox:checked + label {
  color: #0d9e9e;
}
.form__button-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 60px;
}
@media screen and (max-width: 767px) {
  .form__button-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.form__button {
  width: 400px;
  height: 80px;
  background-color: #0d9e9e;
  border-radius: 40px;
  border: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.2em;
  color: #fff;
  line-height: 2em;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media screen and (max-width: 767px) {
  .form__button {
    font-size: 18px;
    width: 100%;
    height: 60px;
    border-radius: 30px;
  }
}
.form__button:hover {
  border: 1px solid #0d9e9e;
  background-color: transparent;
  color: #0d9e9e;
}
.form__button--back {
  width: 170px;
  background-color: #fff;
  border: 1px solid #0d9e9e;
  color: #0d9e9e;
  margin-right: 60px;
}
@media screen and (max-width: 767px) {
  .form__button--back {
    width: 100%;
    margin: 0 0 15px;
  }
}
.form__button--back:hover {
  background-color: #0d9e9e;
  color: #fff;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.breadcrumbs {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  margin-bottom: 40px;
}
@media screen and (max-width: 767px) {
  .breadcrumbs {
    max-width: 100%;
    padding: 0 6%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .breadcrumbs {
    max-width: 100%;
    padding: 0 4%;
  }
}
@media screen and (max-width: 767px) {
  .breadcrumbs {
    margin: 100px 0 20px;
  }
}
.breadcrumbs__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-top: 1px solid #808080;
  padding-top: 20px;
}
.breadcrumbs__link, .breadcrumbs__title {
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
  letter-spacing: 0.1em;
}
.breadcrumbs__title {
  font-weight: 500;
}
.breadcrumbs__link {
  color: #0d9e9e;
  text-decoration: underline;
  position: relative;
  padding-right: 10px;
  margin-right: 5px;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}
.breadcrumbs__link:hover {
  opacity: 0.6;
}
.breadcrumbs__link::before {
  content: "-";
  font-size: 14px;
  font-weight: 400;
  color: #081524;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.white {
  color: #fff;
}

.white-bg {
  background: #fff;
}

.black {
  color: #000;
}

.black-bg {
  background: #000;
}

.overflow {
  overflow: hidden;
}

.pc {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media screen and (max-width: 767px) {
  .pc {
    display: none;
  }
}

.sp {
  display: none;
}
@media screen and (max-width: 767px) {
  .sp {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

.no-margin {
  margin: 0;
}

.flickity-viewport {
  width: 100%;
}

.flickity-prev-next-button {
  width: 100px;
  height: 100px;
}
@media screen and (max-width: 767px) {
  .flickity-prev-next-button {
    width: 50px;
    height: 50px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .flickity-prev-next-button {
    width: 70px;
    height: 70px;
  }
}

.flickity-prev-next-button.previous,
.flickity-prev-next-button.next {
  background: url("./src/img-new/column/icon-arrow.png") no-repeat;
  background-size: cover;
}

.flickity-button.previous {
  left: 8%;
  -webkit-transform: translateY(-50%) rotate(180deg);
          transform: translateY(-50%) rotate(180deg);
}
@media screen and (max-width: 767px) {
  .flickity-button.previous {
    left: 4%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .flickity-button.previous {
    left: 6%;
  }
}

.flickity-prev-next-button.next {
  right: 8%;
}
@media screen and (max-width: 767px) {
  .flickity-prev-next-button.next {
    right: 4%;
  }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
  .flickity-prev-next-button.next {
    right: 6%;
  }
}

.flickity-button-icon {
  display: none;
}

.flickity-button:disabled {
  opacity: 0;
}

/*# sourceMappingURL=/main.css.map */
