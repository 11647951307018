//Flickity
@import '~flickity/dist/flickity.min.css';

//Flatpickr
@import '~flatpickr/dist/flatpickr.css';

@import 'vendors/upward/style-new.css';

//Trump file for overriding css
@import 'components/trumps-new';

.header.scrolled .header__login.header__mypage {
    display: none !important;
}

.t-column__noimage {
    width: 45% !important;
    margin-top: 50px;
    margin-left: 20%;
}

.d-none {
    display: none;
}
