.t-flow__button-disabled {
    background-color: #cccccc !important;
    cursor: not-allowed !important;
}

.t-flow__button-disabled:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(48, 76, 81, 0.2);
    box-shadow: 0 10px 30px 0 rgba(48, 76, 81, 0.2);
    border: none !important;
}

.header__submenu.active {
    max-height: 100px;
}
